* {
  padding: 0;
  box-sizing: border-box;
  color: #000;
}

p {
  margin: 0;
}

#logo {
  position: relative;
  font-family: "Playfair Display", serif;
  font-weight: 600;
  font-size: 4.5vh;
  padding: 2vh;
  text-align: center;
  width: 100vw;
  color: #000;
}

#hamburger {
  position: absolute;
  float: inline-end;
  cursor: pointer;
  padding: 2vh 2vw 0 0;
  top: 0;
  right: 0;
}

.disable-scroll {
  height: 100%;
  overflow: hidden;
  pointer-events: all;
}

body {
  font-family: "Inter", sans-serif;
  color: #000;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 0;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-in;
}

.inverted-body * {
  color: #fff !important;
  background-color: #000;
}

.display-inline * {
  display: inline;
}

#main-container {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.04) 0,
      rgba(0, 0, 0, 0.04) 2px,
      transparent 1px,
      transparent
    ),
    linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.04) 0,
        rgba(0, 0, 0, 0.04) 2px,
        transparent 1px,
        transparent
      )
      9vw;
  background-size: 18vw 18vw;
  z-index: 1;
}

@media screen and (max-width: 1000px) and (min-width: 501px) {
  #main-container {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.04) 0,
        rgba(0, 0, 0, 0.04) 2px,
        transparent 1px,
        transparent
      ),
      linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.04) 0,
          rgba(0, 0, 0, 0.04) 2px,
          transparent 1px,
          transparent
        )
        9vw;
    background-size: 35vw 35vw;
    z-index: 0;
  }
}

@media screen and (max-width: 500px) {
  #main-container {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.04) 0,
        rgba(0, 0, 0, 0.04) 2px,
        transparent 1px,
        transparent
      ),
      linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.04) 0,
          rgba(0, 0, 0, 0.04) 2px,
          transparent 1px,
          transparent
        )
        9vw;
    background-size: 40vw 40vw;
    z-index: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

a {
  text-decoration: none;
}

a:hover {
  color: #f8721b !important;
}

a:visited {
  color: #000;
}

ul {
  list-style-type: none;
  padding: 0;
}

/* NavBar */

.nav-bar {
  top: 0;
  position: sticky;
  background-color: #fff;
}

#menu {
  position: absolute;
  width: 100%;
  max-width: 100%;
  display: flex;
  color: #fff;
  font-size: 1.6vw;
  padding-top: 1vh;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20%;
}

#menu ul {
  padding-top: 1vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: #000;
  top: 0;
  left: 0;
}

#nav-items {
  height: 100vh;
  background-color: #000;
}

.nav-item {
  font-size: 15vh;
  line-height: 15vh;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  display: inline;
  cursor: pointer;
  font-weight: 700;
  padding: 0.2ex 0;
}

.nav-item:hover {
  font-style: italic;
  color: #f8721b;
}

#contact-info-footer {
  position: fixed;
  bottom: 10vh;
  left: 5vw;
  display: flex;
  color: #fff;
  flex-direction: row;
  padding-top: 2vh;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3vw;
  /* line-height: 2vw; */
  height: auto;
}

#menu #contact-info-footer {
  background-color: #000;
}

#menu .contact-info-footer-section {
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1.8vw;
  width: 100%;
  padding: 0 3em;
  flex: 1;
}

@media screen and (max-width: 1000px) and (min-width: 851px) {
  #menu {
    height: 100vh;
    display: block;
  }

  .nav-item {
    font-size: 13vh;
    padding: 0.2ex 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    display: inline;
    cursor: pointer;
    font-weight: 700;
    line-height: 13vh;
  }

  #contact-info-footer {
    position: fixed;
    bottom: 10vh;
    left: 5vw;
    display: flex;
    color: #fff;
    flex-direction: row;
    padding-top: 2vh;
    align-items: center;
    justify-content: space-between;
  }

  #menu .contact-info-footer-section {
    display: flex;
    color: #fff;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.8vw;
    line-height: normal !important;
    width: 100%;
  }
}

@media screen and (max-width: 850px) and (min-width: 651px) {
  #menu {
    height: 100vh;
    display: block;
  }

  .nav-item {
    font-size: 12vh;
    line-height: 12vh;
    padding: 0.2ex 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    display: inline;
    cursor: pointer;
    font-weight: 700;
  }

  #menu .contact-info-footer-section {
    display: flex;
    color: #fff;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 2vw;
    line-height: normal !important;
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .nav-item {
    font-size: 8vh;
    line-height: 9vh;
    padding: 0.2ex 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    display: inline;
    cursor: pointer;
    font-weight: 700;
  }

  #menu {
    display: flex;
    gap: 2vh;
    width: 100%;
    flex-wrap: wrap-reverse;
    align-items: unset;
    min-height: 75vh;
    margin-top: 2vh;
  }

  #menu #contact-info-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    left: auto;
  }

  #menu .contact-info-footer-section {
    display: inline-block;
    color: #fff;
    flex-direction: column;
    font-size: 2.5vw;
    line-height: normal !important;
    width: 100%;
    justify-content: flex-end;
    text-align: right;
    font-size: 2.5vh;
    line-height: normal !important;
    padding: 0;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 420px) {
  .nav-item {
    font-size: 14vw;
    line-height: 16vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    display: inline;
    cursor: pointer;
    font-weight: 700;
  }

  #menu .contact-info-footer-section {
    font-size: 2vh;
    line-height: normal !important;
  }

  #menu {
    margin-top: 2vh;
  }
}

/* Sections */
section {
  padding: 9vh 8vw 0 8vw;
}

section h3 {
  max-width: 100%;
  color: #000;
  font-size: 4vw;
  margin-bottom: 2vh;
}

section h3:hover {
  font-style: italic;
}

/* Flower */
#flower-container {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 100vh;
  z-index: -1;
}

#flower-image-container {
  display: flex;
  height: 100vh;
  z-index: -1;
  align-items: flex-end;
  justify-content: flex-end;
}

#flower {
  padding-bottom: 15vh;
  padding-right: 10vw;
  width: 100%;
  z-index: -1;
}

/* Home */
#home {
  background-size: cover;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  max-height: 90vh;
  height: auto;
  gap: 2vw;
  width: 80%;
}

.big-left-text {
  font-size: 5.5vw;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 1vw 0;
  width: 80%;
  line-height: 6.5vw;
}

section h2 {
  font-style: italic;
  font-size: 300%;
  font-weight: 300;
}

@media screen and (max-width: 900px) and (min-width: 801px) {
  .big-left-text {
    font-size: 7.4vw;
    padding: 0 0 1vw 0;
    width: 90%;
    line-height: 11vw;
  }
  #home {
    width: 90%;
  }
}

@media screen and (max-width: 800px) and (min-width: 601px) {
  .big-left-text {
    font-size: 8vw;
    padding: 0 0 3vw 0;
    width: 90%;
    line-height: 11vw;
  }
  #home {
    padding-top: 5vh;
    padding-bottom: 0;
    margin-bottom: 0;
    max-height: 90vh;
    min-height: 30vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    gap: 2vw;
    width: 100vw;
  }
}

@media screen and (max-width: 600px) and (min-width: 451px) {
  #home {
    width: 100vw;
    padding-top: 5vh;
    padding-bottom: 2vh;
    margin-bottom: 0;
    max-height: 80vh;
    min-height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    gap: 2vw;
  }
  .big-left-text {
    font-size: 9vw;
    padding: 2.2vw 0;
    width: 100%;
    line-height: 11vw;
  }
  #home h2 {
    font-size: 7vw;
  }
}

@media screen and (max-width: 450px) {
  #home {
    width: 100%;
    padding-top: 5vh;
    padding-bottom: 1vh;
    margin-bottom: 0;
    max-height: 80vh;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    gap: 2vw;
  }
  .big-left-text {
    font-size: 14vw;
    padding: 2vh 0 0 0;
    width: 100%;
    line-height: 17vw;
  }
  #home h2 {
    font-size: 7.6vw;
    margin-bottom: 0;
  }
}

/* Image Slideshow */
#slideshow {
  padding-top: 5vh;
  width: 100%;
  padding-bottom: 4vh;
}

.fuckingShitAssPicture {
  width: 100%;
  max-height: 90vh;
}

.fuckingShitAssPicture img {
  width: 100%;
  padding-bottom: 2vh;
}

@media screen and (max-width: 1300px) and (min-width: 651px) {
  .fuckingShitAssPicture {
    width: 100%;
    padding-bottom: 0;
    min-height: 60vh;
  }
}

@media screen and (max-width: 650px) {
  .fuckingShitAssPicture {
    width: 100%;
    padding-bottom: 0;
    min-height: 40vh;
  }
}

/* Services */
#services a {
  color: #f8721b !important;
}

#services p {
  padding: 10vh 0 !important;
}

/* Lifecycles */
section#lifecycles {
  padding-bottom: 9vh;
}

#lifecycles {
  font-size: 3vw;
}

#lifecycles h4 {
  margin: 0;
}

.lifecycle-description {
  font-size: 1.5vw;
  width: 70% !important;
}

.lifecycle:hover {
  /* background-color: #86d5b04a; */
  background-color: rgba(248, 115, 27, 0.1);
  border: 1px solid rgba(248, 115, 27, 0.3);
}

.lifecycle:hover .learn-more-button {
  display: flex;
}

.lifecycle-title {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}

.lifecycle {
  padding: 1em;
  border-radius: 30px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: 1px solid rgb(255, 255, 255);
}

.learn-more-button {
  display: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 2vw;
  color: #f8731b !important;
  font-weight: 100;
  font-family: "Inter", sans-serif !important;
}

.learn-more-button:hover {
  font-weight: 300;
  font-family: "Inter", sans-serif !important;
}

@media screen and (max-width: 850px) {
  #lifecycles h4 {
    font-size: 8vw;
    /* margin-top: 0; */
  }
}

#lifecycles div {
  width: 100%;
  cursor: pointer;
  text-align: left;
}

.lifecycles-container {
  display: flex;
  /* gap: 1ex; */
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* padding-top: 5vh; */
}

@media screen and (max-width: 900px) {
  .lifecycles-container {
    display: flex;
    /* gap: 1ex; */
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
}

.lifecycles-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 1ex;
  /* padding: 0; */
}

/* About */
#about {
  padding-bottom: 5vh;
}

#about p {
  line-height: 1.5;
  color: #000;
}

#about h3 {
  margin-top: 0;
  margin-bottom: 1vh;
}

#about h5 {
  font-family: "Playfair Display", serif;
  font-size: 2.5vw;
}

/* #about-bloom {
  width: 80% !important;
  text-align: justify;
  left: 20%;
} */

.about-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2em 0;
}
.about-card img {
  max-width: 50%;
}

.about-card div {
  max-width: 50%;
  text-align: justify;
}

.about-card h5 {
  margin-top: 0;
}

#maya {
  padding: 0;
  padding-left: 5vw;
}

#maya h5 {
  text-align: right;
  margin: 0 0 1ex 0;
}

#nomi {
  padding: 0;
  padding-right: 5vw;
}

#nomi h5 {
  margin: 0 0 1ex 0;
}

/* Let's Get Started */

#lets-get-started {
  width: 100%;
  min-height: 85vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap-reverse;
}

#slogan h1.big-left-text {
  width: 60%;
}

#cta {
  text-align: right;
  width: max-content;
  padding: 15vh 0 10vh 0;
}

#cta a h2.cta-link {
  line-height: 4vw;
  font-size: 4vw;
  font-style: italic;
  margin-right: 2vw;
}

@media screen and (max-width: 1000px) and (min-width: 601px) {
  #lets-get-started {
    width: 100%;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap !important;
  }

  #cta {
    padding: 15vh 0 5vh 0;
    text-align: center;
    max-width: 80vw;
  }

  #cta a h2.cta-link {
    font-size: 6vw;
    font-style: italic;
    line-height: normal;
  }

  #slogan h1.big-left-text {
    width: 100%;
    text-align: center;
  }

  #slogan {
    width: 70%;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  #lets-get-started {
    width: 100%;
    /* margin-top: 5vh; */
    min-height: 40vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 3vh;
    line-height: normal;
  }

  #slogan h1.big-left-text {
    font-size: 12vw;
    width: 100%;
    line-height: normal;
  }

  #cta {
    padding: 15vh 0 5vh 0;
    text-align: right;
    max-width: 60vw;
  }

  #cta a h2.cta-link {
    font-size: 8vw;
    font-style: italic;
    line-height: normal;
  }
}

/* Contact */
#contact {
  width: 100%;
  margin-bottom: 3vh;
}

#contact h1 {
  text-align: right;
  width: 100%;
}

#contact form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

form div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding: 1ex;
}

#contact label {
  line-height: 4vw;
  font-style: italic;
  padding: 1ex 2em;
  border-radius: 0;
}

#contact input,
#contact textarea {
  background: transparent;
  border: none;
  border-top: 1px solid #000000;
  font-size: 2vw;
  font-family: "Inter", sans-serif;
  padding-bottom: 2vh;
}

#contact input:focus,
#contact textarea:focus {
  border-top: 2px solid #000000;
}

textarea {
  resize: none;
  height: 5vh;
}

textarea#message {
  height: 100% !important;
  max-height: 100% !important;
}

#contact button {
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  background-color: transparent;
  margin-top: 5vh;
}

.cta-link:hover {
  color: #f8721b;
  font-style: italic;
}

.cta-link {
  cursor: pointer;
  padding: 0;
  width: max-content !important;
  text-decoration: none;
  background-image: linear-gradient(#f8721b, #f8721b);
  background-size: 0 1px;
  background-position-y: 100%;
  background-position-x: 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
}

.cta-link:hover,
.cta-link:focus,
.cta-link:active {
  background-size: 100% 1px;
  background-position-x: 0%;
}

textarea:focus,
input:focus {
  outline: none;
}

@media screen and (max-width: 850px) {
  #contact form {
    flex-direction: column;
  }

  #contact input,
  #contact textarea {
    font-size: 4vh;
  }

  #contact button {
    justify-content: center;
    margin: 2vh 0;
  }
}

#contact .contact-info-footer-section {
  display: flex;
  color: #000;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1.8vw;
  width: 100%;
  padding: 0 3em;
  background-color: transparent;
}

#contact .contact-info-footer-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1.8vw;
  width: 100%;
  padding: 0 3em;
}

@media screen and (max-width: 1000px) and (min-width: 851px) {
  #contact #contact-info-footer {
    color: #000;
  }

  #contact .contact-info-footer-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.8vw;
    line-height: normal !important;
    width: 100%;
  }

  #contact button {
    justify-content: flex-end;
    margin: 2vh 0;
  }

  #contact input,
  #contact textarea {
    font-size: 4vh;
  }

  #contact form {
    display: block;
  }
}

@media screen and (max-width: 850px) and (min-width: 651px) {
  #contact #contact-info-footer {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  #contact .contact-info-footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    line-height: normal !important;
    flex: 1;
  }
}

@media screen and (max-width: 650px) {
  #contact #contact-info-footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 7vw;
  }

  #contact .contact-info-footer-section {
    flex-direction: column;
    font-size: 2.5vw;
    line-height: normal !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: right;
    font-size: 2.5vh;
    line-height: normal !important;
    padding: 0;
    margin: 1em 0;
  }

  #contact button {
    justify-content: center;
    margin: 2vh 0;
  }

  button h1.cta-link.big-left-text {
    font-size: 10vw !important;
  }

  #contact input,
  #contact textarea {
    font-size: 5vw;
  }
}

@media screen and (max-width: 420px) {
  #contact .contact-info-footer-section {
    font-size: 2vh;
    line-height: normal !important;
  }

  #contact button {
    justify-content: center;
    margin: 2vh 0;
  }

  button h1.cta-link.big-left-text {
    font-size: 10vw !important;
  }

  #contact input,
  #contact textarea {
    font-size: 6vw;
  }
}

/* Footer */
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-top: 8vh;
  background-color: #fff;
  text-align: center;
  padding-top: 1vh;
  padding-right: 1vw;
  padding-bottom: 1vh;
  padding-left: 1vw;
  color: rgba(0, 0, 0, 0.8);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 1vw;
  font-weight: 100;
}

@media screen and (max-width: 1000px) and (min-width: 401px) {
  footer {
    margin-top: 5vh;
    background-color: #fff;
    text-align: center;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
    padding-left: 1vw;
    color: rgba(0, 0, 0, 0.8);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    font-weight: 100;
  }
}

@media screen and (max-width: 400px) {
  footer {
    margin-top: 3vh;
    background-color: #fff;
    text-align: center;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
    padding-left: 1vw;
    color: rgba(0, 0, 0, 0.8);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    font-weight: 100;
  }
}
